<template>
  <div>
    <h2 class="text-center fw-normal fw-md-medium text-dark fs-4 fs-sm-3 fs-md-2 fs-lg-4 mb-3">優惠加購</h2>
    <p class="text-end mb-3">共須加購 {{ productNum }} 個商品，已選擇 {{ sumNum }} 個商品</p>
    <div class="row">
      <div class="position-relative col-md-6 mb-3" v-for="addon in addonProductList" :key="addon">
        <div
          class="position-absolute start-0 top-0 end-0 bottom-0 bg-opacity-75 bg-white d-flex justify-content-center align-items-center z-index-10"
          v-if="addon.RealStock === 0"
        >
          <p class="bg-gray text-white py-1 px-3 rounded-pill">已售完</p>
        </div>
        <div
          class="position-absolute start-0 top-0 end-0 bottom-0 opacity-75 bg-white d-flex justify-content-center align-items-center z-index-10"
          v-else-if="addon.DisplayStock === 0"
        >
          <p class="bg-gray text-white py-1 px-3 rounded-pill">售完補貨中</p>
        </div>
        <div class="prdhover row align-items-center py-1 h-100">
          <div class="col-1">
            <input class="addonCheckbox form-check-input" type="checkbox" v-model="selectedAddon" :value="addon" id="addOn">
          </div>
          <div class="col-11">
            <div class="d-flex align-items-center">
              <a href="#" class="d-block w-25 w-lg-20 me-2 me-md-3" @click.prevent="openModal(addon.Id)">
                <img :src="addon.Image" class="img-fluid" alt="加購商品">
              </a>
              <div class="d-flex flex-column justify-content-between flex-fill">
                <h3 class="text-dark fs-6 mb-2">
                  <a href="#" @click.prevent="openModal(addon.Id)">{{ addon.Name }}</a>
                </h3>
                <div class="d-flex justify-content-between" :class="addon.DisplayStock === 1 ? 'align-items-center': 'align-items-end'">
                  <p class="text-nowrap text-danger fs-6 fs-md-5 me-2">NT$ {{ $currency.currency(addon.Price) }}</p>
                  <div class="w-50">
                    <!-- 數量 -->
                    <div class="productNumber input-group">
                      <button
                        type="button"
                        class="btn bg-white border-dark rounded-0"
                        @click.prevent="minusNum(addon)"
                        :class="{'disabled': addon.Quantity === 1}"
                      >
                        -
                      </button>
                      <input
                        type="number"
                        class="form-control border-dark text-center"
                        aria-label="product number"
                        min="1"
                        onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
                        v-model.number="addon.Quantity"
                        @change="numInput(addon)"
                      />
                      <button type="button" class="btn bg-white border-dark rounded-0" @click.prevent="addCartNum(addon)">
                        +
                      </button>
                    </div>
                    <p v-if="addon.RealStock === 1" class="text-center text-danger">可購買數量： {{ addon.RealStock }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddonProduct ref="addonModal" :product-id-double="productId"></AddonProduct>
  </div>
</template>

<script>
import ProductListSvc from '@/service/productlist-service.js'
import AddonProduct from '@/components/AddonProduct.vue'

export default {
  components: {
    AddonProduct
  },
  data () {
    return {
      addonProductList: [], // * 加購品列表
      selectedAddon: [], // * 被挑選的加購品
      productId: ''
    }
  },
  props: {
    productNum: {
      type: Number,
      default: 0
    },
    addonList: {
      type: Array,
      default: () => ([])
    }
  },
  emits: ['getAddon'],
  methods: {
    // * 加購商品
    getAddonList () {
      ProductListSvc.GetAddonProductList('02').then(data => {
        if (!data) {
          this.$router.push('/productlist')
          return
        }

        this.addonProductList = data
        this.addonProductList.sort((a, b) => {
          return parseInt(a.IntDisplayOrder) - parseInt(b.IntDisplayOrder)
        })

        this.addonProductList.forEach((prd) => {
          prd.Type = 'B'
          prd.Quantity = 1
          // *被挑選的加購品
          this.addonList.forEach((add) => {
            if (prd.Id === add.Id) {
              prd.Quantity = add.Quantity
              this.selectedAddon.push(prd)
            }
          })
        })
      })
    },
    addCartNum (prd) { //* 購物車數量 ++
      prd.Quantity++
      if (prd.Quantity > prd.DisplayStock) {
        prd.Quantity = prd.DisplayStock
      }
    },
    minusNum (prd) { //* 購物車數量 --
      prd.Quantity--
      if (prd.Quantity < 1) {
        prd.Quantity = 1
      }
    },
    //* 商品 input 輸入，數量不可輸入空值、0、非正整數
    numInput (pd) {
      const rule = /^[0-9]*[1-9][0-9]*$/
      if (pd.Quantity === '') {
        pd.Quantity = 1
      } else if (pd.Quantity > pd.DisplayStock) {
        pd.Quantity = pd.DisplayStock
      } else if (pd.Quantity === 0) {
        pd.Quantity = 1
      } else if (!rule.test(pd.Quantity)) {
        pd.Quantity = 1
      }
    },
    checkSum () {
      const sumNum = this.selectedAddon.reduce(
        (previousValue, currentValue) => previousValue + currentValue.Quantity, 0
      )
      if (sumNum !== this.productNum) {
        this.$swal.fire({
          title: '加購品數量需與主商品數量一致',
          allowOutsideClick: true,
          confirmButtonColor: '#003894',
          confirmButtonText: '確認',
          width: 400,
          customClass: {
            title: 'text-class',
            confirmButton: 'confirm-btn-class'
          }
        })
      }
    },
    openModal (id) {
      this.productId = id
      this.$refs.addonModal.openModal()
    }
  },
  computed: {
    sumNum () {
      const sum = this.selectedAddon.reduce(
        (previousValue, currentValue) => previousValue + currentValue.Quantity, 0
      )
      return sum
    }
  },
  watch: {
    addonProductList: {
      handler: function (n) {
        if (this.selectedAddon.length !== 0) {
          this.selectedAddon.forEach((select) => {
            n.forEach((list) => {
              if (list.Id === select.Id) {
                select.Quantity = list.Quantity
              }
            })
          })
          this.checkSum()
          this.$emit('getAddon', this.selectedAddon)
        }
      },
      deep: true
    },
    selectedAddon (n) {
      const checkNum = n.filter(item => item.Quantity === 0)
      if (checkNum.length !== 0) {
        this.$swal.fire({
          title: '請選擇加購品數量',
          allowOutsideClick: true,
          confirmButtonColor: '#03894',
          confirmButtonText: '確認',
          width: 400,
          customClass: {
            title: 'text-class',
            confirmButton: 'confirm-btn-class'
          }
        })
        return
      }
      this.checkSum()
      this.$emit('getAddon', n)
    }
  },
  mounted () {
    this.getAddonList()
  }
}
</script>

<style lang="scss" scoped>
img {
  object-fit: cover;
}

.prdhover:hover{
  background-color: #F0F0F2;
}

.addonCheckbox{
  width: 16px;
  height: 16px;
  @media (min-width: 992px) {
    width: 18px;
    height: 18px;
  }
}

.productNumber{
  .btn.disabled{
    pointer-events: initial;
    cursor: not-allowed;
  }
}
</style>
