import axios from 'axios'
import { ProductListUM } from '@/model/ui/productlist-ui-model'

export default {
  GetProductList: async function () {
    const url = `${process.env.VUE_APP_API}/api/product/listedbycategory?member=S0701`
    const res = await axios.get(url)
    if (res == null || res.data == null || res.data.rtnCode !== 0) {
      return null
    }

    if (res.data.info == null) {
      return null
    }

    const data = res.data.info.products
    // for迴圈處理,賦予給每個值
    const list = data.map((item) => {
      const d = new ProductListUM()
      // 左ProductUM 右API回傳res.data.info.products
      d.Id = item.productId
      d.Name = item.name
      d.Price = item.price
      d.MSRP = item.oldPrice
      d.Image = item.productImage
      d.Stock = item.stock
      d.Slogan = item.slogan
      d.Date = item.date
      d.RealStock = item.realStock
      d.DisplayStock = item.displayStock

      return d
    })

    return list
  },
  // * 取得雙11商品
  GetAddonProductList: async function (id) {
    id = id || ''
    const url = `${process.env.VUE_APP_API}/api/product/listedbycategory?member=S0703${id}`
    const res = await axios.get(url)
    if (res == null || res.data == null || res.data.rtnCode !== 0) {
      return null
    }

    if (res.data.info == null) {
      return null
    }

    const data = res.data.info.products

    // for迴圈處理,賦予給每個值
    const list = data.map((item) => {
      const d = new ProductListUM()
      // 左ProductUM 右API回傳res.data.info.products
      d.Id = item.productId
      d.OptionId = item.optionId
      d.Name = item.name
      d.Price = item.price
      d.MSRP = item.oldPrice
      d.Image = item.productImage
      d.Stock = item.stock
      d.Slogan = item.slogan
      d.Date = item.date
      d.RealStock = item.realStock
      d.DisplayStock = item.displayStock
      d.IntDisplayOrder = item.intDisplayOrder
      d.SubCatagory = item.subCatagory

      return d
    })

    return list
  }
}
