export class ProductListUM {
  Id;// string || int
  OptionId; // string
  Name;// string
  Price;// number
  MSRP; // number
  Image; // string
  Stock; // number
  Slogan; // string
  Date; // number
  RealStock; // number
  DisplayStock; // number
  IntDisplayOrder; // number
  SubCatagory; // string
  Type; // string
}

export class ProductListImageUM {
  ImageUrl; // string
}
