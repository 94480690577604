<template>
  <div class="modal" tabindex="-1" ref="addonModal">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ product.Name }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <!-- <p>{{ productIdDouble }}</p> //*product ID -->
          <div class="images mb-3 mb-lg-0 text-center">
            <img :src="product.Image" alt="商品圖片" class="img-fluid w-50">
          </div>
          <div class="details d-flex flex-column justify-content-between mt-3 mt-md-0">
            <div>
              <h1 class="fs-3 mb-4">{{ product.Name }}</h1>
              <p class="text-danger fs-4">
                NT$ <span class="fs-2 fs-lg-1">{{ $currency.currency(product.Price) }}</span>
              </p>
              <ul v-if="product.Tags" class="d-flex list-unstyled fw-light mb-3 mb-md-4">
                <li class="feature me-2" v-for="tag in product.Tags" :key="tag+'2'">{{ tag }}</li>
              </ul>
              <div class="fs-10 mb-4 mb-md-6" v-html="product.ShortDescription"></div>
            </div>
          </div>
          <!-- 商品描述 / 商品規格 -->
          <ul class="productDescription nav nav-pills text-center mb-3 mb-md-4" id="pills-tab" role="tablist">
            <li class="nav-item d-inline-block" role="presentation">
              <button class="nav-link rounded-0 fw-normal fw-md-medium text-dark fs-4 fs-sm-3 fs-md-2 fs-lg-4 px-0 mx-md-3" id="pills-home-tab" type="button" role="tab" data-bs-toggle="pill" data-bs-target="#pills-tabDescription" @click.prevent="changeDescription = !changeDescription" :class="{ active: changeDescription}">商品描述</button>
            </li>
            <li class="nav-item d-inline-block" role="presentation">
              <button class="nav-link rounded-0 fw-normal fw-md-medium text-dark fs-4 fs-sm-3 fs-md-2 fs-lg-4 px-0 ms-4 mx-md-3" id="pills-profile-tab" type="button" role="tab" data-bs-toggle="pill" data-bs-target="#pills-tabSpecification" @click.prevent="changeDescription = !changeDescription" :class="{ active: !changeDescription}">商品規格</button>
            </li>
          </ul>
          <div class="productDescriptionContent tab-content mx-auto mb-6">
            <div class="tab-pane fade" :class="{ show: changeDescription, active: changeDescription }" aria-labelledby="pills-home-tab" id="pills-tabDescription" aria-selected="true">
              <div class="text-dark descriptionContentDouble" v-html="product.Description"></div>
            </div>
            <div class="tab-pane fade" :class="{ show: !changeDescription, active: !changeDescription}" aria-labelledby="pills-profile-tab" id="pills-tabSpecification">
              <div class="h-md-100 text-dark specificationContent" v-html="product.Specification"></div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">關閉</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal'
import ProductSvc from '@/service/product-service.js'

export default {
  data () {
    return {
      addonProduct: '',
      modal: '',
      product: {},
      changeDescription: true
    }
  },
  props: {
    productIdDouble: {
      type: String,
      default: ''
    }
  },
  methods: {
    openModal () {
      this.modal.show()
    },
    getAddonProduct () {
      ProductSvc.GetProduct(this.productIdDouble).then(data => {
        this.product = data
        this.changeDescription = true
      })
    }
  },
  watch: {
    // 有值傳進來再打api
    productIdDouble (n) {
      if (n) {
        this.getAddonProduct()
      }
    }
  },
  mounted () {
    this.modal = new Modal(this.$refs.addonModal, {
      backdrop: 'static'
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/pages/_product';

.images{
  width: 100%;
}
</style>
